import React, { useState } from 'react';
import styled from 'styled-components';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { doc, increment, updateDoc } from 'firebase/firestore';
import { db, auth } from '../firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import { FaCheckCircle, FaTimes, FaShieldAlt, FaLock, FaInfinity, FaCoins, FaCrown, FaBolt, FaRocket } from 'react-icons/fa';

const stripePromise = loadStripe('pk_live_51Q7mlYAo8DkCAAeqKbJ2AkantXN1kH4UOBXESsBNI7leUB3cNCcYTdruYLNT1yrpJ92ZlvbQJeFK0seBqkJVMH7A00ilD1QgC0');

// Theme definition
const theme = {
  colors: {
    background: '#ffffff',
    surface: '#f8fafc',
    primary: '#3b82f6',
    primaryDark: '#2563eb',
    success: '#10b981',
    error: '#ef4444',
    gold: '#f59e0b',
    text: {
      primary: '#1e293b',
      secondary: '#64748b',
      light: '#94a3b8'
    },
    border: '#e2e8f0',
    gradient: {
      start: '#3b82f6',
      end: '#2563eb'
    }
  },
  shadows: {
    sm: '0 1px 3px rgba(0, 0, 0, 0.1)',
    md: '0 4px 6px rgba(0, 0, 0, 0.1)',
    lg: '0 10px 15px rgba(0, 0, 0, 0.1)',
    xl: '0 20px 25px rgba(0, 0, 0, 0.1)',
    highlight: '0 0 0 3px rgba(59, 130, 246, 0.15)'
  }
};

// Styled Components
const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px;
  background: ${theme.colors.background};
  border-radius: 24px;
  box-shadow: ${theme.shadows.xl};
`;

const CloseButton = styled.button`
  position: absolute;
  top: 24px;
  right: 24px;
  background: none;
  border: none;
  color: ${theme.colors.text.secondary};
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  &:hover {
    background: ${theme.colors.surface};
    color: ${theme.colors.text.primary};
  }
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 48px;
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: 800;
  background: linear-gradient(135deg, ${theme.colors.gradient.start}, ${theme.colors.gradient.end});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 16px;
  letter-spacing: -0.02em;
`;

const Subtitle = styled.p`
  font-size: 18px;
  color: ${theme.colors.text.secondary};
  max-width: 600px;
  margin: 0 auto;
`;

const OptionsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  margin-bottom: 48px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const PlanCard = styled.div<{ featured?: boolean }>`
  background: ${props => props.featured ?
    `linear-gradient(135deg, ${theme.colors.gradient.start}08, ${theme.colors.gradient.end}08)` :
    theme.colors.background};
  border: 2px solid ${props => props.featured ? theme.colors.primary : theme.colors.border};
  border-radius: 16px;
  padding: 32px;
  position: relative;
  transition: all 0.3s ease;

  ${props => props.featured && `
    box-shadow: ${theme.shadows.highlight};
  `}

  &:hover {
    transform: translateY(-4px);
    box-shadow: ${theme.shadows.lg};
  }
`;

const PlanBadge = styled.div`
  position: absolute;
  top: -12px;
  right: 24px;
  background: ${theme.colors.gold};
  color: white;
  padding: 4px 16px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

const PlanHeader = styled.div`
  margin-bottom: 24px;
`;

const PlanTitle = styled.h3`
  font-size: 24px;
  font-weight: 700;
  color: ${theme.colors.text.primary};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const PlanPrice = styled.div`
  font-size: 48px;
  font-weight: 800;
  color: ${theme.colors.primary};
  margin-bottom: 8px;

  span {
    font-size: 20px;
    font-weight: 600;
    color: ${theme.colors.text.secondary};
  }
`;

const PlanDescription = styled.p`
  font-size: 16px;
  color: ${theme.colors.text.secondary};
  margin-bottom: 24px;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 32px 0;
`;

const Feature = styled.li`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  color: ${theme.colors.text.primary};
  margin-bottom: 16px;

  svg {
    color: ${theme.colors.success};
    flex-shrink: 0;
  }
`;

const PaymentSection = styled.div`
  max-width: 480px;
  margin: 0 auto;
  padding: 32px;
  background: ${theme.colors.surface};
  border-radius: 16px;
  box-shadow: ${theme.shadows.md};
`;

const PaymentHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.colors.border};

  h3 {
    font-size: 18px;
    font-weight: 600;
    color: ${theme.colors.text.primary};
    margin: 0;
  }

  p {
    font-size: 14px;
    color: ${theme.colors.text.secondary};
    margin: 0;
  }
`;

const SecurityBadge = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: ${theme.colors.text.secondary};
  padding: 8px 16px;
  background: ${theme.colors.background};
  border-radius: 8px;
  margin-bottom: 24px;
`;

const CardElementWrapper = styled.div`
  background: ${theme.colors.background};
  border: 1px solid ${theme.colors.border};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
`;

const TokenInput = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
  padding: 16px;
  background: ${theme.colors.background};
  border: 1px solid ${theme.colors.border};
  border-radius: 8px;
`;

const TokenInputField = styled.input`
  width: 100%;
  border: none;
  font-size: 18px;
  padding: 8px;
  color: ${theme.colors.text.primary};
  background: transparent;

  &:focus {
    outline: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const TokenPrice = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: ${theme.colors.text.secondary};
`;

const SubmitButton = styled.button<{ processing?: boolean }>`
  width: 100%;
  padding: 16px;
  background: ${props => props.processing ? theme.colors.text.secondary : theme.colors.primary};
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: ${props => props.processing ? 'not-allowed' : 'pointer'};
  transition: all 0.2s;

  &:hover:not(:disabled) {
    background: ${theme.colors.primaryDark};
    transform: translateY(-1px);
  }

  &:active:not(:disabled) {
    transform: translateY(0);
  }
`;

const Message = styled.div<{ type: 'error' | 'success' }>`
  margin-top: 16px;
  padding: 12px;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  
  ${props => props.type === 'error' ? `
    background: ${theme.colors.error}10;
    color: ${theme.colors.error};
  ` : `
    background: ${theme.colors.success}10;
    color: ${theme.colors.success};
  `}
`;

// Payment Form Component
const PaymentForm: React.FC<{
  amount: number;
  tokens?: number;
  isSubscription?: boolean;
  onSuccess: () => void;
}> = ({
  amount,
  tokens,
  isSubscription = false,
  onSuccess,
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [isProcessing, setIsProcessing] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [user] = useAuthState(auth);

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
      if (!stripe || !elements || !user) return;

      setIsProcessing(true);
      setError('');
      setSuccess('');

      try {
        const cardElement = elements.getElement(CardElement);
        if (!cardElement) return;

        // Create payment method
        const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });

        if (paymentMethodError) {
          setError(paymentMethodError.message || 'Payment failed');
          return;
        }

        const idToken = await user.getIdToken();
        const endpoint = isSubscription ? 'create_subscription' : 'create_payment_intent';

        // Call our backend
        const response = await fetch(
          `https://us-central1-photoop-28a4c.cloudfunctions.net/${endpoint}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({
              paymentMethodId: paymentMethod.id,
              amount: Math.round(amount * 100),
              tokens,
              isSubscription
            }),
          }
        );

        const data = await response.json();

        if (isSubscription) {
          // Handle subscription
          const { clientSecret, subscriptionId } = data;

          const { error: confirmationError } = await stripe.confirmCardPayment(clientSecret);

          if (confirmationError) {
            setError(confirmationError.message || 'Payment failed');
          } else {
            setSuccess('Subscription activated! Enjoy unlimited tokens.');
            onSuccess();
          }
        } else {
          // Handle one-time payment (your existing code)
          const { clientSecret } = data;
          const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: { card: cardElement },
          });

          if (result.error) {
            setError(result.error.message || 'Payment failed');
          } else if (result.paymentIntent?.status === 'succeeded') {
            const userDocRef = doc(db, 'userTokens', user.email!);
            await updateDoc(userDocRef, { tokens: increment(tokens!) });
            setSuccess('Payment successful! Your tokens have been added.');
            onSuccess();
          }
        }
      } catch (err) {
        console.error('Payment error:', err);
        setError('An error occurred during payment');
      } finally {
        setIsProcessing(false);
      }
    };

    return (
      <PaymentSection>
        <PaymentHeader>
          <FaLock size={24} color={theme.colors.primary} />
          <div>
            <h3>Secure Payment</h3>
            <p>Your transaction is protected</p>
          </div>
        </PaymentHeader>

        <SecurityBadge>
          <FaShieldAlt /> 256-bit SSL Encrypted
        </SecurityBadge>

        <form onSubmit={handleSubmit}>
          <CardElementWrapper>
            <CardElement options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: theme.colors.text.primary,
                  '::placeholder': {
                    color: theme.colors.text.light,
                  },
                  fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
                },
              },
            }} />
          </CardElementWrapper>

          <SubmitButton processing={isProcessing} disabled={isProcessing}>
            {isProcessing ? 'Processing...' : `Pay $${amount.toFixed(2)}`}
          </SubmitButton>

          {error && (
            <Message type="error">
              <FaTimes /> {error}
            </Message>
          )}
          {success && (
            <Message type="success">
              <FaCheckCircle /> {success}
            </Message>
          )}
        </form>
      </PaymentSection>
    );
  };

const PurchaseTokens: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [selectedOption, setSelectedOption] = useState<'subscription' | 'tokens'>('subscription');
  const [tokenAmount, setTokenAmount] = useState<number>(100);
  const TOKEN_PRICE = 0.10; // $0.10 per token

  return (
    <Container>
      <CloseButton onClick={onClose}>
        <FaTimes size={20} />
      </CloseButton>

      <Header>
        <Title>Choose Your Plan</Title>
        <Subtitle>
          Select the perfect plan for your needs and start creating amazing content today
        </Subtitle>
      </Header>

      <OptionsGrid>
        <PlanCard featured onClick={() => setSelectedOption('subscription')}>
          <PlanBadge>Most Popular</PlanBadge>
          <PlanHeader>
            <PlanTitle>
              <FaCrown size={24} color={theme.colors.gold} />
              Premium Plan
            </PlanTitle>
            <PlanPrice>$8.99 <span>/month</span></PlanPrice>
            <PlanDescription>
              Unlimited access to all features with no token restrictions
            </PlanDescription>
          </PlanHeader>

          <FeatureList>
            <Feature>
              <FaInfinity size={20} />
              Unlimited tokens
            </Feature>
            <Feature>
              <FaBolt size={20} />
              Priority processing
            </Feature>
            <Feature>
              <FaRocket size={20} />
              See image generation history
            </Feature>
            <Feature>
              <FaCheckCircle size={20} />
              Download images
            </Feature>
          </FeatureList>

          {selectedOption === 'subscription' && (
            <Elements stripe={stripePromise}>
              <PaymentForm
                amount={8.99}
                isSubscription={true}
                onSuccess={onClose}
              />
            </Elements>
          )}
        </PlanCard>

        <PlanCard onClick={() => setSelectedOption('tokens')}>
          <PlanHeader>
            <PlanTitle>
              <FaCoins size={24} color={theme.colors.primary} />
              Pay As You Go
            </PlanTitle>
            <PlanPrice>${TOKEN_PRICE.toFixed(2)} <span>/token</span></PlanPrice>
            <PlanDescription>
              Purchase tokens as needed with no monthly commitment
            </PlanDescription>
          </PlanHeader>

          <FeatureList>
            <Feature>
              <FaCheckCircle size={20} />
              Flexible token purchases
            </Feature>
            <Feature>
              <FaCheckCircle size={20} />
              No monthly commitment
            </Feature>
            <Feature>
              <FaCheckCircle size={20} />
              Standard support
            </Feature>
          </FeatureList>

          {selectedOption === 'tokens' && (
            <>
              <TokenInput>
                <TokenInputField
                  type="number"
                  min="1"
                  value={tokenAmount}
                  onChange={(e) => setTokenAmount(Math.max(1, parseInt(e.target.value) || 0))}
                  placeholder="Enter number of tokens"
                />
                <TokenPrice>${(tokenAmount * TOKEN_PRICE).toFixed(2)}</TokenPrice>
              </TokenInput>

              <Elements stripe={stripePromise}>
                <PaymentForm
                  amount={tokenAmount * TOKEN_PRICE}
                  tokens={tokenAmount}
                  onSuccess={onClose}
                />
              </Elements>
            </>
          )}
        </PlanCard>
      </OptionsGrid>
    </Container>
  );
};

export default PurchaseTokens;