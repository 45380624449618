// src/components/PurchasePage.tsx

import React, { useState, useEffect } from 'react';
import { Lock, CreditCard, XCircle, Shield, Receipt } from 'lucide-react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  doc,
  setDoc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  deleteDoc,
  increment,
} from 'firebase/firestore';
import { storage, db, auth, analytics } from './firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { templateCategories, TemplateService, Template } from './templates';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { logEvent } from 'firebase/analytics';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import FontAwesome CSS
import Navbar from './Navbar';

// Interface for props
interface PurchasePageProps {
  photos: File[];
  gender: string;
  ethnicity: string;
  templates: string[];
}

interface OrderData {
  orderId: string;
  userId: string | null;
  userEmail: string | null;
  gender: string;
  triggerWord: string;
  ethnicity: string;
  templateIds: string[];
  status: string;
  timestamp: string;
  totalPrice: number;
  referralCode: string | null;  // Add this
}

// Initialize Stripe with your publishable key
const stripePromise = loadStripe('pk_live_51Q7mlYAo8DkCAAeqKbJ2AkantXN1kH4UOBXESsBNI7leUB3cNCcYTdruYLNT1yrpJ92ZlvbQJeFK0seBqkJVMH7A00ilD1QgC0');

const colors = {
  primary: '#007BFF',
  secondary: '#6C757D',
  success: '#28A745',
  danger: '#DC3545',
  warning: '#FFC107',
  info: '#17A2B8',
  light: '#F8F9FA',
  dark: '#343A40',
  white: '#FFFFFF',
  muted: '#6C757D',
  gray: '#6C757D',
  onBackground: '#111827',
  onPrimary: '#FFFFFF',
};

const PurchasePage: React.FC<PurchasePageProps> = ({
  photos,
  gender,
  ethnicity,
  templates,
}) => {
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [user] = useAuthState(auth);
  const [isPaymentCompleted, setIsPaymentCompleted] = useState<boolean>(false);
  const [hasUpgraded, setHasUpgraded] = useState<boolean>(false);
  const [isUserEligibleForFreeOrder, setIsUserEligibleForFreeOrder] = useState<boolean>(false);
  const [hasDoneFreeOrder, setHasDoneFreeOrder] = useState<boolean>(false);

  // Instantiate the TemplateService
  const templateService = new TemplateService(templateCategories);

  // Helper function to calculate the total number of photos
  const calculateTotalPhotos = (templateIds: string[]): number => {
    let totalPhotos = 0;
    let freeTemplatesCount = 0;
    const maxFreeTemplates = 3;

    templateIds.forEach((templateId) => {
      const template = templateService.findTemplateById(templateId);
      if (!template) return;

      let isFreeForUser = false;

      if (template.isPaid === false && !hasDoneFreeOrder) {
        isFreeForUser = true;
      } else if (isUserEligibleForFreeOrder && freeTemplatesCount < maxFreeTemplates) {
        isFreeForUser = true;
        freeTemplatesCount += 1;
      }

      totalPhotos += isFreeForUser ? 50 : 50;
    });

    return totalPhotos;
  };

  // Check if the user has made a free order before
  useEffect(() => {
    const checkIfUserHasDoneFreeOrder = async () => {
      if (user) {
        try {
          const freeOrderDocRef = doc(db, 'freeOrders', user.uid);
          const freeOrderDoc = await getDoc(freeOrderDocRef);
          if (freeOrderDoc.exists()) {
            setHasDoneFreeOrder(true);
          } else {
            setHasDoneFreeOrder(false);
          }
        } catch (error) {
          console.error('Error checking free order status:', error);
        }
      }
    };

    checkIfUserHasDoneFreeOrder();
  }, [user]);

  // Check if the user is eligible for a free order (e.g., via a voucher)
  useEffect(() => {
    const checkFreeOrderEligibility = async () => {
      if (user && user.email) {
        try {
          const q = query(
            collection(db, 'free_order_users'),
            where('email', '==', user.email)
          );
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            setIsUserEligibleForFreeOrder(true);
          }
        } catch (error) {
          console.error('Error checking free order eligibility:', error);
        }
      }
    };

    checkFreeOrderEligibility();
  }, [user]);

  const calculateTotalPrice = (
    templateIds: string[],
    hasUpgraded: boolean,
    hasDoneFreeOrder: boolean,
    isUserEligibleForFreeOrder: boolean
  ): number => {
    let totalPrice = 0;
    let freeTemplatesCount = 0;
    const maxFreeTemplates = 3;

    const whitelistedEmails = ['owenkamilkhoury@gmail.com', 'Ameliaamerritt@gmail.com', 'sabrinachernobyl0131@gmail.com', 'zachcasey10@gmail.com'];
    if (user?.email && whitelistedEmails.includes(user.email)) {
      return 0;
    }

    templateIds.forEach((templateId) => {
      const template = templateService.findTemplateById(templateId);
      if (!template) return;

      const isLinkedInCategory = templateCategories.some(
        (category) =>
          category.name === "LinkedIn Professional" &&
          category.templates.some((t) => t.id === templateId)
      );

      const basePrice = isLinkedInCategory ? 9.99 : 5.99;
      const price = template.isDiscounted ? basePrice * 0.75 : basePrice;

      let isFreeForUser = false;

      if (template.isPaid === false && !hasDoneFreeOrder) {
        isFreeForUser = true;
      } else if (isUserEligibleForFreeOrder && freeTemplatesCount < maxFreeTemplates) {
        isFreeForUser = true;
        freeTemplatesCount += 1;
      }

      if (!isFreeForUser) {
        totalPrice += price;
      }
    });

    return totalPrice;
  };

  const totalPrice = calculateTotalPrice(templates, hasUpgraded, hasDoneFreeOrder, isUserEligibleForFreeOrder);
  const totalPhotos = calculateTotalPhotos(templates);

  // Function to handle Google Sign-In
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  const userEmail = user?.email;

  const logFreeOrder = async (userId: string) => {
    try {
      const freeOrderRef = doc(db, 'freeOrders', userId);

      await setDoc(
        freeOrderRef,
        {
          userId: userId,
          lastFreeOrderTimestamp: new Date(),
          freeOrderCount: increment(1), // Increment the free order count
        },
        { merge: true }
      );

      console.log(`Logged free order for user ID: ${userId}`);
    } catch (error) {
      console.error('Error logging free order:', error);
    }
  };

  const handlePurchase = async () => {
    try {
      await validateInput();
      setIsProcessing(true);
      const orderId = uuidv4();

      let referralCode = null;
      const storedReferralData = localStorage.getItem('referralCode');

      if (storedReferralData) {
        try {
          const referralData = JSON.parse(storedReferralData);
          // Check if the referral hasn't expired
          if (new Date().getTime() < referralData.expires) {
            referralCode = referralData.code;
          } else {
            // Clean up expired referral
            localStorage.removeItem('referralCode');
          }
        } catch (error) {
          console.error('Error parsing stored referral data:', error);
        }
      }

      const orderData = {
        orderId,
        userId: user?.uid,
        userEmail: userEmail,
        gender: gender,
        triggerWord: gender === "male" ? "John" : "Jane",
        ethnicity: ethnicity,
        templateIds: templates,
        status: 'pending', // Start with pending status
        timestamp: new Date().toISOString(),
        totalPrice: totalPrice,
        referralCode,
      };

      // Create Firestore document first
      await createFirestoreEntry(orderId, orderData);

      try {
        // Try uploading photos
        await uploadPhotos(orderId);

        // Update status to inProgress only after successful upload
        await setDoc(doc(db, 'orders', orderId), { ...orderData, status: 'inProgress' }, { merge: true });

        // Continue with remaining operations
        await sendOrder(orderData);

        if (totalPrice === 0 && user) {
          await logFreeOrder(user.uid);
        }

        if (isUserEligibleForFreeOrder && user && user.email) {
          await removeUserFromFreeOrderUsers(user.email);
        }

        // Log analytics
        if (analytics) {
          logEvent(analytics, totalPrice > 0 ? 'paid_order' : 'free_order', {
            price: totalPrice.toFixed(2),
            templates_selected: templates.length,
          });
        }

        navigate(`/orders?order_id=${orderId}`);
      } catch (error) {
        // If photos fail to upload, clean up the order
        await deleteDoc(doc(db, 'orders', orderId));
        throw error;
      }
    } catch (error: any) {
      console.error('Error during purchase process:', error);
      alert(error.message || 'Error during purchase. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const removeUserFromFreeOrderUsers = async (email: string) => {
    try {
      const q = query(
        collection(db, 'free_order_users'),
        where('email', '==', email)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // Assuming email is unique and there's only one document
        const docId = querySnapshot.docs[0].id;
        await deleteDoc(doc(db, 'free_order_users', docId));
        console.log(`Removed ${email} from free_order_users collection.`);
        setIsUserEligibleForFreeOrder(false); // Update state
      }
    } catch (error) {
      console.error('Error removing user from free_order_users:', error);
      // You might want to handle this error differently depending on your requirements
    }
  };

  const validateInput = async () => {
    if (!userEmail) {
      throw new Error('User is not authenticated.');
    }

    if (photos.length === 0) {
      throw new Error('No photos to upload!');
    }

    if (templates.length === 0) {
      throw new Error('Please select at least one template.');
    }

    if (!ethnicity) {
      throw new Error('Please select an ethnicity.');
    }

    if (!gender) {
      throw new Error('Please select a gender.');
    }
  };

  const uploadPhotos = async (orderId: string): Promise<string[]> => {
    console.log('PurchasePage - Uploading photos:', photos.length, photos.map(p => p.name));
    const uploadPromises = photos.map((photo) => {
      const storageRef = ref(storage, `uploaded_photos/${orderId}/${photo.name}`);
      return uploadBytes(storageRef, photo)
        .then((snapshot) => getDownloadURL(snapshot.ref))
        .catch((error) => {
          console.error(`Error uploading file ${photo.name}: `, error);
          throw error;
        });
    });

    const urls = await Promise.all(uploadPromises);
    console.log('All files uploaded:', urls);
    return urls;
  };

  const createFirestoreEntry = async (orderId: string, orderData: any) => {
    try {
      await setDoc(doc(db, 'orders', orderId), orderData);
      console.log(`Firestore entry created successfully for order ID: ${orderId}`);
    } catch (error) {
      console.error('Error creating Firestore entry:', error);
      throw new Error('Failed to create order in Firestore.');
    }
  };

  const sendOrder = async (orderData: any) => {
    if (user) {
      const idToken = await user.getIdToken();

      const response = await fetch(
        'https://us-central1-photoop-28a4c.cloudfunctions.net/publish_order',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify(orderData),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Response from publish_order:', errorText);
        throw new Error('Failed to send order.');
      }
    } else {
      throw new Error('User is not authenticated.');
    }
  };

  const handleBack = () => {
    navigate('/upload-photos');
  };

  // PaymentForm Component
  const PaymentForm: React.FC<{ amount: number }> = ({ amount }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState('');
    const [isProcessingPayment, setIsProcessingPayment] = useState(false);

    const handleSubmit = async (event: React.FormEvent) => {
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      setIsProcessingPayment(true);

      try {
        if (!user) {
          setErrorMessage('User not authenticated');
          return;
        }

        const idToken = await user.getIdToken();

        const response = await fetch(
          'https://us-central1-photoop-28a4c.cloudfunctions.net/create_payment_intent',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
            body: JSON.stringify({ amount: Math.round(amount * 100) }), // amount in cents
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          console.error('Response from create_payment_intent:', errorText);
          throw new Error('Failed to create payment intent');
        }

        const data = await response.json();

        const clientSecret = data.clientSecret;

        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement)!,
            billing_details: {
              email: user.email || undefined,
            },
          },
        });

        if (result.error) {
          setErrorMessage(result.error.message || 'Payment failed');
        } else {
          if (result.paymentIntent?.status === 'succeeded') {
            setIsPaymentCompleted(true);
            handlePurchase();
          }
        }
      } catch (error) {
        console.error('Error processing payment:', error);
        setErrorMessage('An error occurred during payment.');
      } finally {
        setIsProcessingPayment(false);
      }
    };

    return (
      <PaymentFormContainer onSubmit={handleSubmit}>
        <FormHeader>
          <IconWrapper>
            <Lock size={24} />
          </IconWrapper>
          <FormTitle>Secure Payment</FormTitle>
        </FormHeader>
        <Description>
          Complete your purchase securely. All transactions are encrypted.
        </Description>
        <CardElementContainer>
          <CardElement
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  fontSize: '16px',
                  color: '#1E293B',
                  fontFamily: "'Inter', sans-serif",
                  '::placeholder': {
                    color: '#64748B',
                  },
                },
                invalid: {
                  color: '#DC2626',
                },
              },
            }}
          />
        </CardElementContainer>
        <PayButton type="submit" disabled={!stripe || isProcessingPayment}>
          {isProcessingPayment ? (
            'Processing...'
          ) : (
            <>
              Pay ${amount.toFixed(2)}
              <CreditCard size={18} />
            </>
          )}
        </PayButton>
        {errorMessage && (
          <ErrorMessage>
            <XCircle size={16} />
            {errorMessage}
          </ErrorMessage>
        )}
        <SecurityNote>
          <Shield size={16} />
          Your payment information is protected and encrypted
        </SecurityNote>
      </PaymentFormContainer>
    );
  };

  return (
    <>
      <GlobalStyle />
      <FullscreenContainer>
        <Navbar
          navItems={[
            {
              label: 'Home',
              to: '/',
            },
          ]}
          showSignOutButton={!!user}
          currentStep={3}
        />

        {!user ? (
          <ContentContainer>
            <TextContainer>
              <Title>Please Sign In to Complete Your Order</Title>
              <Description>
                Sign in with Google to proceed with your purchase and generate your
                personalized AI portraits.
              </Description>
              <GoogleSignInButton onClick={handleGoogleSignIn}>
                <GoogleIcon className="fab fa-google" />
                Sign in with Google
              </GoogleSignInButton>
            </TextContainer>
          </ContentContainer>
        ) : (
          <ContentContainer>
            {totalPrice > 0 && (
              <OrderSection>
                <Title>Complete Your Order</Title>
                <OrderSummary>
                  <SummaryHeader>
                    <IconWrapper>
                      <Receipt size={24} color="#2563EB" />
                    </IconWrapper>
                    <SummaryTitle>Order Summary</SummaryTitle>
                  </SummaryHeader>

                  <SummaryItem>
                    <span>Templates Selected</span>
                    <span>{templates.length}</span>
                  </SummaryItem>

                  <SummaryItem>
                    <span>Total Images</span>
                    <span>{totalPhotos}</span>
                  </SummaryItem>

                  <SummaryItem>
                    <span>Gender</span>
                    <span>{gender}</span>
                  </SummaryItem>

                  <SummaryItem>
                    <span>Ethnicity</span>
                    <span>{ethnicity}</span>
                  </SummaryItem>

                  <SummaryTotal>
                    <span>Total Price</span>
                    <span>${totalPrice.toFixed(2)}</span>
                  </SummaryTotal>
                </OrderSummary>
                <MoneyBackGuarantee />
              </OrderSection>
            )}

            <PaymentSection>
              {!isPaymentCompleted ? (
                totalPrice > 0 ? (
                  <Elements stripe={stripePromise}>
                    <PaymentForm amount={totalPrice} />
                  </Elements>
                ) : (
                  <CenteredContainer>
                    <Title>Complete Your Order</Title>
                    <OrderSummary>
                      <SummaryTitle>Order Summary</SummaryTitle>
                      <SummaryItem>
                        <span>Templates Selected:</span>
                        <span>{templates.length}</span>
                      </SummaryItem>
                      <SummaryItem>
                        <span>Total Images:</span>
                        <span>{totalPhotos}</span>
                      </SummaryItem>
                      <SummaryItem>
                        <span>Gender:</span>
                        <span>{gender}</span>
                      </SummaryItem>
                      <SummaryItem>
                        <span>Ethnicity:</span>
                        <span>{ethnicity}</span>
                      </SummaryItem>
                      <SummaryTotal>
                        <span>Total Price:</span>
                        <span>${totalPrice.toFixed(2)}</span>
                      </SummaryTotal>
                    </OrderSummary>

                    <PurchaseButton
                      onClick={handlePurchase}
                      disabled={isProcessing}
                      aria-label="Complete Purchase"
                    >
                      {isProcessing ? 'Processing...' : 'Get My Portraits'}
                      <i className="fas fa-circle-check" aria-hidden="true"></i>
                    </PurchaseButton>
                  </CenteredContainer>
                )
              ) : (
                <SuccessMessage>
                  <h2>Payment Successful!</h2>
                  <p>Your order is being processed.</p>
                </SuccessMessage>
              )}
            </PaymentSection>
          </ContentContainer>
        )}

        {/* Bottom Navigation */}
        <BottomNav>
          <NavButton onClick={handleBack}>Back</NavButton>
        </BottomNav>
      </FullscreenContainer>
    </>
  );
};

export default PurchasePage;

// Styled Components
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');
  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
    background-color: #f5f7fa;
    color: #333;
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(18px);
  } 
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Fullscreen container for the entire page
const FullscreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

// Content container to position text and payment form side by side
const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  flex: 1;
  padding: 54px 72px;
  animation: ${fadeIn} 0.8s ease-in;

  @media (max-width: 1024px) {
    padding: 45px 54px;
  }

  @media (max-width: 768px) {
    padding: 36px 27px;
    padding-bottom: 150px;
  }
`;

const OrderSection = styled.div`
  flex: 1;
  min-width: 320px;
  max-width: 480px;
`;

const OrderSummary = styled.div`
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border: 1px solid #E2E8F0;
  padding: 32px;
  transition: box-shadow 0.3s ease;
  margin-top: 24px;
  
  &:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
  }
`;

const SummaryHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #E2E8F0;
`;

const SummaryTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  color: #1E293B;
  margin: 0;
`;

const SummaryItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  
  span:first-child {
    color: #64748B;
    font-size: 0.95rem;
  }
  
  span:last-child {
    color: #1E293B;
    font-weight: 500;
    font-size: 0.95rem;
  }
`;

const SummaryTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #E2E8F0;
  
  span:first-child {
    color: #1E293B;
    font-weight: 600;
    font-size: 1.1rem;
  }
  
  span:last-child {
    color: #2563EB;
    font-weight: 700;
    font-size: 1.2rem;
  }
`;

const Title = styled.h1`
  font-size: 1.7rem;
  font-weight: 700;
  margin: 0 0 24px 0;
  color: #1E293B;
  letter-spacing: -0.02em;
  line-height: 1.3;
  background: linear-gradient(135deg, #1E293B 0%, #334155 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 48px;
    height: 3px;
    background: linear-gradient(135deg, #3B82F6 0%, #2563EB 100%);
    border-radius: 4px;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.4rem;
  }
`;

const Description = styled.p`
  font-size: 0.99rem;
  font-weight: 400;
  color: #555;
  line-height: 1.6;
  margin-bottom: 22.5px;
`;

const PaymentSection = styled.div`
  flex: 1;
  min-width: 270px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const PaymentFormContainer = styled.form`
  background: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border: 1px solid #E2E8F0;
  padding: 32px;
  width: 100%;
  transition: box-shadow 0.3s ease;
  &:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
  }

  margin-top: 48px;
`;

const FormHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #E2E8F0;
`;

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(37, 99, 235, 0.1);
  color: #2563EB;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FormTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1E293B;
  margin: 0;
`;

const CardElementContainer = styled.div`
  padding: 16px;
  border: 1px solid #E2E8F0;
  border-radius: 10px;
  background: #F8FAFC;
  margin-bottom: 24px;
  transition: border-color 0.3s ease;
  &:hover {
    border-color: #2563EB;
  }
`;

const PayButton = styled.button`
  width: 100%;
  padding: 14px 24px;
  background: linear-gradient(135deg, #3B82F6 0%, #2563EB 100%);
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 0.95rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.3s ease;
  
  &:hover:not(:disabled) {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(37, 99, 235, 0.25);
  }
  
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #DC2626;
  background: #FEF2F2;
  border: 1px solid #FEE2E2;
  border-radius: 8px;
  padding: 12px;
  margin-top: 16px;
  font-size: 0.9rem;
`;

const SecurityNote = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #64748B;
  font-size: 0.9rem;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #E2E8F0;
`;

const PaymentButton = styled.button`
  background-color: ${colors.primary};
  color: ${colors.white};
  padding: 15px 30px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease, transform 0.1s ease;

  &:hover {
    background-color: ${colors.dark};
  }

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    background-color: ${colors.secondary};
    cursor: not-allowed;
  }
`;

const SuccessMessage = styled.div`
  text-align: center;

  h2 {
    font-size: 1.62rem;
    color: #28a745;
    margin-bottom: 9px;
  }

  p {
    font-size: 0.9rem;
    color: #555;
  }
`;

const PurchaseButton = styled.button`
  gap: 12px;
  background: linear-gradient(135deg, #2563EB, #1D4ED8); 
  color: #ffffff;
  margin-top: 40px;
  padding: 15px 30px;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  transition: background 0.3s ease, transform 0.1s ease;

  &:hover {
    background: linear-gradient(135deg, #1D4ED8, #1E40AF);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    background-color: ${colors.secondary};
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    margin-bottom: 40px;
  }
`;

// Styled Components for Sign-In Button
const GoogleSignInButton = styled.button`
  background-color: #4285F4;
  color: white;
  padding: 12.6px 25.2px;
  font-size: 0.9rem;
  margin-top: 27px;
  border: none;
  border-radius: 5.4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: inline-flex;
  align-items: center;

  &:hover {
    background-color: #357AE8;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2.7px rgba(66, 133, 244, 0.5);
  }
`;

const GoogleIcon = styled.i`
  font-size: 1.08rem;
  margin-right: 9px;
`;

// Bottom Navigation Bar
const BottomNav = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px 24px;
  background-color: #ffffff;
  border-top: 0.9px solid #e0e0e0;

  @media (max-width: 768px) {
    padding: 13.5px 27px;
  }

  @media (max-width: 480px) {
    padding: 9px 18px;
  }
`;

const NavButton = styled.button<{ primary?: boolean }>`
  background-color: ${(props) => (props.primary ? colors.primary : 'transparent')};
  color: ${(props) => (props.primary ? colors.onPrimary : colors.primary)};
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  border: ${(props) => (props.primary ? 'none' : `2px solid ${colors.primary}`)};
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.primary ? colors.secondary : `${colors.primary}1A`)};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px ${colors.primary}33;
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
`;

// Text Container
const TextContainer = styled.div`
  flex: 1;
  max-width: 540px;
  margin-right: 45px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 36px;
  }
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;


const GuaranteeContainer = styled.div`
  background: linear-gradient(to right, #F8FAFC, #F1F5F9);
  border: 1px solid #E2E8F0;
  border-radius: 12px;
  padding: 20px;
  margin: 24px 0;
  display: flex;
  align-items: center;
  gap: 16px;
  transition: all 0.2s ease;
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  }
`;

const GuaranteeIconBox = styled.div`
  width: 48px;
  height: 48px;
  background: linear-gradient(135deg, #22C55E, #16A34A);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const GuaranteeContent = styled.div`
  flex: 1;
`;

const GuaranteeTitle = styled.h3`
  color: #0F172A;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0 4px 0;
`;

const GuaranteeText = styled.p`
  color: #64748B;
  font-size: 0.9rem;
  line-height: 1.5;
  margin: 0;
`;

// Create the MoneyBackGuarantee component
const MoneyBackGuarantee: React.FC = () => (
  <GuaranteeContainer>
    <GuaranteeIconBox>
      <Shield size={24} color="white" />
    </GuaranteeIconBox>
    <GuaranteeContent>
      <GuaranteeTitle>30-Day Money Back Guarantee</GuaranteeTitle>
      <GuaranteeText>
        Not satisfied with your portraits? Get a full refund within 30 days, no questions asked.
      </GuaranteeText>
    </GuaranteeContent>
  </GuaranteeContainer>
);
