// src/CustomModelsPage.tsx
import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, storage, db } from './firebaseConfig';
import { ref, getDownloadURL, deleteObject } from 'firebase/storage';
import { collection, doc, deleteDoc, onSnapshot, setDoc, serverTimestamp } from 'firebase/firestore';
import Navbar from './Navbar';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { FaPlus, FaShoppingCart, FaTrash, FaBullhorn, FaCrown, FaImage, FaDownload, FaHistory, FaChevronRight } from 'react-icons/fa';
import NewModelTrainer from './ModelTrainingComponents/NewModelTrainer';
import ModelQueryUI from './ModelTrainingComponents/ModelQueryUI';
import ModelProgressUI from './ModelTrainingComponents/ModelProgressUI';
import PurchaseTokens from './ModelTrainingComponents/PurchaseTokens';
import Sidebar from './ModelTrainingComponents/Sidebar';
const colors = { background: '#f3f4f6', primary: '#3b82f6', primaryDark: '#2563eb', secondary: '#e5e7eb', text: '#1f2937', textSecondary: '#4b5563', border: '#d1d5db', accent: '#7c3aed', danger: '#ef4444', sidebarBackground: '#E5E4E2' };

const shimmer = keyframes`
  0% { transform: translateX(-150%); }
  100% { transform: translateX(150%); }
`;

const float = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-2px); }
`;

// Add these styled components after the existing float animation
const slideIn = keyframes`
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
`;

const FuturisticBanner = styled.div`
  background: linear-gradient(135deg, #4f46e5 0%, #3b82f6 100%);
  padding: 14px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1),
              inset 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
  
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.05) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.05) 50%,
      rgba(255, 255, 255, 0.05) 75%,
      transparent 75%
    );
    background-size: 8px 8px;
    opacity: 0.4;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(
      90deg,
      transparent,
      rgba(255, 255, 255, 0.1),
      transparent
    );
    animation: ${shimmer} 4s infinite;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

const BannerText = styled.div`
  font-size: 1rem;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  gap: 12px;
  position: relative;
  z-index: 1;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.2px;
`;

const BenefitsList = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-left: 24px;
  
  @media (max-width: 1024px) {
    gap: 16px;
  }
  
  @media (max-width: 768px) {
    display: none;
  }
`;

const Benefit = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.9);
  font-size: 0.9rem;
  
  svg {
    color: #fef3c7;
  }
`;

const UpgradeButton = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(255, 255, 255, 0.1);
  padding: 6px 12px;
  border-radius: 20px;
  margin-left: auto;
  font-size: 0.9rem;
  color: white;
  transition: background 0.2s ease-in-out;
  
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  @media (max-width: 768px) {
    display: none;
  }
`;

const HighlightText = styled.span`
  color: #fef3c7;
  font-weight: 700;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: 'Inter', sans-serif;
  color: ${colors.text};
  background: ${colors.background};
  overflow: hidden;
`;
const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  overflow: hidden;
`;


const MainContent = styled.div`
  flex: 1;
  padding: 32px;
  display: flex;
  flex-direction: column;
  background: ${colors.background};
  overflow-y: auto;
  @media (max-width: 768px) { padding: 16px; }
`;
const PlaceholderText = styled.p`
  font-size: 1rem;
  color: ${colors.textSecondary};
  margin-top: 16px;
`;

const LoadingMessage = styled.p`
  font-size: 1rem;
  color: ${colors.textSecondary};
  text-align: center;
  margin-top: 32px;
`;
const ErrorMessage = styled.p`
  font-size: 1rem;
  color: ${colors.danger};
  text-align: center;
  margin-top: 32px;
`;

const FuturisticBannerText = styled.span`
  font-size: 1.1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  gap: 12px;
  i { font-size: 1.5rem; color: white; }
`;

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const floatAnimation = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

export const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 64px);
  padding: 2rem;
  background: linear-gradient(135deg, #f6f8fc 0%, #e9eef8 100%);
`;

export const SignInCard = styled.div`
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  padding: 3rem;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  max-width: 480px;
  width: 100%;
  text-align: center;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, #3b82f6, #7c3aed, #3b82f6);
    background-size: 200% 200%;
    animation: ${gradientAnimation} 3s linear infinite;
  }
`;

export const WelcomeTitle = styled.h1`
  font-size: 2.5rem;
  color: #1f2937;
  margin-bottom: 0.5rem;
  font-weight: 700;
`;

export const WelcomeSubtitle = styled.p`
  font-size: 1.125rem;
  color: #6b7280;
  margin-bottom: 2rem;
  line-height: 1.6;
`;

export const SignInButton = styled.button`
  background: linear-gradient(135deg, #3b82f6 0%, #2563eb 100%);
  color: white;
  padding: 1rem 2rem;
  font-size: 1.125rem;
  font-weight: 600;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 12px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(37, 99, 235, 0.2);
  }

  &:active {
    transform: translateY(0);
  }

  i {
    font-size: 1.25rem;
  }
`;

export const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-top: 2.5rem;
`;

export const FeatureItem = styled.div`
  text-align: left;
  display: flex;
  align-items: flex-start;
  gap: 1rem;

  i {
    color: #3b82f6;
    font-size: 1.5rem;
  }
`;

export const FeatureText = styled.div`
  h3 {
    color: #1f2937;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.25rem;
  }

  p {
    color: #6b7280;
    font-size: 0.875rem;
    line-height: 1.4;
  }
`;

type Model = {
  id: string;
  trainingStatus: string;
  modelName: string;
  storagePath: string;
  timestamp: any;
  userEmail: string;
  triggerWord: string;
};
const CustomModelsPage: React.FC = () => {
  const [user, loadingUser, errorUser] = useAuthState(auth);
  const [models, setModels] = useState<Model[]>([]);
  const [tokens, setTokens] = useState<number | null>(null);
  const [isLoadingTokens, setIsLoadingTokens] = useState<boolean>(false);
  const [showPurchaseModal, setShowPurchaseModal] = useState<boolean>(false);
  const [selectedModel, setSelectedModel] = useState<Model | null>(null);
  const [prompt, setPrompt] = useState<string>('');
  const [generatedImages, setGeneratedImages] = useState<string[]>([]);
  const [isLoadingModels, setIsLoadingModels] = useState<boolean>(false);
  const [isLoadingImages, setIsLoadingImages] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isEditingModelId, setIsEditingModelId] = useState<string | null>(null);
  const [editedModelName, setEditedModelName] = useState<string>('');
  const [isAddingNewModel, setIsAddingNewModel] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    if (!user || !user.email) return;

    setIsLoadingTokens(true);

    // Check subscription status and tokens in parallel
    const userDocRef = doc(db, 'users', user.email);
    const tokenDocRef = doc(db, 'userTokens', user.email);

    const unsubscribe = onSnapshot(userDocRef, async (userDoc) => {
      const subscriptionData = userDoc.data();
      const hasActiveSubscription = subscriptionData?.subscriptionStatus === 'active';

      // If they have an active subscription, set tokens to Infinity
      if (hasActiveSubscription) {
        setTokens(Infinity);
        setIsLoadingTokens(false);
        return;
      }

      // Otherwise check their regular token balance
      const tokenUnsubscribe = onSnapshot(tokenDocRef, (tokenDoc) => {
        if (tokenDoc.exists()) {
          const data = tokenDoc.data();
          setTokens(data.tokens);
        } else {
          // Initialize new users with 20 tokens
          setDoc(tokenDocRef, {
            userId: user.email,
            tokens: 12,
            lastUpdated: serverTimestamp()
          })
            .then(() => { setTokens(12); })
            .catch((error) => {
              console.error('Error initializing token balance:', error);
              setTokens(null);
            });
        }
        setIsLoadingTokens(false);
      });

      return () => tokenUnsubscribe();
    });

    return () => unsubscribe();
  }, [user]);


  useEffect(() => {
    if (!user || !user.email) return;
    setIsLoadingModels(true);
    setError(null);
    const modelsCollectionRef = collection(db, 'image_models', user.email, 'models');
    const unsubscribe = onSnapshot(modelsCollectionRef, (querySnapshot) => {
      const userModels: Model[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        userModels.push({
          id: doc.id,
          trainingStatus: data.trainingStatus || 'complete',
          modelName: data.modelName,
          storagePath: data.storagePath,
          timestamp: data.timestamp,
          userEmail: data.userEmail,
          triggerWord: data.triggerWord || 'John'
        });
      });
      setModels(userModels);

      const hasStarterModel = userModels.some(
        (model) => model.id === 'starterModel'
      );

      if (!hasStarterModel) {
        const starterModelDocRef = doc(modelsCollectionRef, 'starterModel');
        setDoc(starterModelDocRef, {
          trainingStatus: 'complete',
          modelName: 'PhotoOp Cat',
          storagePath: 'starter_model_weights/pytorch_lora_weights.safetensors',
          timestamp: serverTimestamp(),
          userEmail: user.email,
          triggerWord: 'cat'
        }).catch((err) => console.error('Error creating starter model:', err));
      }

      setIsLoadingModels(false);
    }, (error) => {
      console.error('Error fetching models:', error);
      setError('Failed to load models.');
      setIsLoadingModels(false);
    });
    return () => unsubscribe();
  }, [user]);
  useEffect(() => {
    if (models.length > 0 && !selectedModel) {
      setSelectedModel(models[0]);
    }
  }, [models, selectedModel]);
  const handleDeleteModel = async (modelId: string, storagePath: string, trainingStatus: string) => {
    if (!user?.email) {
      alert('User email is not available.');
      return;
    }
    const confirmed = window.confirm('Are you sure you want to delete this model? This action cannot be undone.');
    if (!confirmed) return;
    try {
      const modelDocRef = doc(db, 'image_models', user.email, 'models', modelId);
      await deleteDoc(modelDocRef);
      if (trainingStatus !== 'inProgress') {
        const storageRef = ref(storage, storagePath);
        await deleteObject(storageRef);
      }
      setModels((prevModels) => prevModels.filter((model) => model.id !== modelId));
      alert('Model successfully deleted.');
    } catch (error) {
      console.error('Error deleting model:', error);
      alert('Failed to delete the model. Please try again.');
    }
  };
  const handleGenerateImage = async () => {
    if (!user || !prompt.trim() || !selectedModel || !selectedModel.storagePath) {
      alert('You must be signed in, select a model, and provide a prompt to generate images.');
      return;
    }
    try {
      setIsLoadingImages(true);
      setGeneratedImages([]);
      const idToken = await user.getIdToken();
      const endpoint = 'https://us-central1-photoop-28a4c.cloudfunctions.net/call_runpod_endpoint';
      const payload = { email: user.email, prompt, modelStoragePath: selectedModel.storagePath };
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${idToken}` },
        body: JSON.stringify(payload)
      });
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error: ${response.status} - ${errorText}`);
      }
      const responseData = await response.json();
      const imagePath = responseData?.output?.image_path;
      if (!imagePath) {
        throw new Error('Image path not returned from the server.');
      }
      const imageRef = ref(storage, imagePath);
      const maxRetries = 60;
      let attempts = 0;
      let imageUrl = '';
      while (attempts < maxRetries) {
        try {
          imageUrl = await getDownloadURL(imageRef);
          break;
        } catch (error: any) {
          if (error.code === 'storage/object-not-found') {
            await new Promise((resolve) => setTimeout(resolve, 5000));
            attempts++;
          } else {
            throw error;
          }
        }
      }
      if (imageUrl) {
        setGeneratedImages([imageUrl]);
      } else {
        throw new Error('Failed to retrieve generated image.');
      }
    } catch (error) {
      console.error('Error generating images:', error);
      alert('Failed to generate images. Please try again.');
    } finally {
      setIsLoadingImages(false);
    }
  };
  const handleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };
  const navItems = [{ label: 'Home', to: '/', icon: <i className="fas fa-home"></i> }];
  const handleModelAdded = (newModel: Model) => {
    setModels((prevModels) => [...prevModels, newModel]);
    setSelectedModel(newModel);
    setIsAddingNewModel(false);
  };
  if (loadingUser || isLoadingModels || isLoadingTokens) {
    return (
      <PageContainer>
        <Navbar navItems={navItems} showSignOutButton={true} />
        <LoadingMessage>Loading...</LoadingMessage>
      </PageContainer>
    );
  }
  if (errorUser || error) {
    return (
      <PageContainer>
        <Navbar navItems={navItems} showSignOutButton={true} />
        <ErrorMessage>An error occurred: {errorUser?.message || error}</ErrorMessage>
      </PageContainer>
    );
  }
  if (!user) {
    return (
      <PageContainer>
        <Navbar />
        <SignInContainer>
          <SignInCard>
            <WelcomeTitle>Welcome to Custom Styles</WelcomeTitle>
            <WelcomeSubtitle>
              Create custom AIs trained on your unique style
            </WelcomeSubtitle>

            <SignInButton onClick={handleSignIn}>
              <i className="fab fa-google"></i>
              Continue with Google
            </SignInButton>

            <FeatureGrid>
              <FeatureItem>
                <i className="fas fa-magic"></i>
                <FeatureText>
                  <h3>Custom AI Models</h3>
                  <p>Train unique AIs with your style</p>
                </FeatureText>
              </FeatureItem>

              <FeatureItem>
                <i className="fas fa-bolt"></i>
                <FeatureText>
                  <h3>Fast Generation</h3>
                  <p>Create images in seconds with your styles</p>
                </FeatureText>
              </FeatureItem>

              <FeatureItem>
                <i className="fas fa-gift"></i>
                <FeatureText>
                  <h3>Free Credits</h3>
                  <p>Start with 20 free tokens to explore</p>
                </FeatureText>
              </FeatureItem>

              <FeatureItem>
                <i className="fas fa-lock"></i>
                <FeatureText>
                  <h3>Secure Storage</h3>
                  <p>Your styles are safely stored in the cloud</p>
                </FeatureText>
              </FeatureItem>
            </FeatureGrid>
          </SignInCard>
        </SignInContainer>
      </PageContainer>
    );
  }
  const filteredModels = models.filter((model) => model.modelName.toLowerCase().includes(searchTerm.toLowerCase()) || model.triggerWord.toLowerCase().includes(searchTerm.toLowerCase()));
  return (
    <PageContainer>
      <Navbar navItems={navItems} showSignOutButton={true} />
      <FuturisticBanner onClick={() => setShowPurchaseModal(true)}>
        <BannerText>
          <FaCrown />
          Upgrade to Premium
        </BannerText>
        <BenefitsList>
          <Benefit>
            <FaImage />
            Unlimited Images
          </Benefit>
          <Benefit>
            <FaHistory />
            View History
          </Benefit>
          <Benefit>
            <FaDownload />
            Download Images
          </Benefit>
        </BenefitsList>
        <UpgradeButton>
          Upgrade Now
          <FaChevronRight />
        </UpgradeButton>
      </FuturisticBanner>
      <ContentContainer>
        <Sidebar
          user={user}
          tokens={tokens}
          selectedModel={selectedModel}
          models={models}
          searchTerm={searchTerm}
          onSearchChange={setSearchTerm}
          onModelSelect={(model) => {
            setSelectedModel(model);
            setIsAddingNewModel(false);
            setShowPurchaseModal(false);
          }}
          onDeleteModel={handleDeleteModel}
          onAddNewModel={() => {
            setIsAddingNewModel(true);
            setSelectedModel(null);
            setShowPurchaseModal(false);
          }}
          onPurchaseTokens={() => {
            setShowPurchaseModal(true);
            setIsAddingNewModel(false);
            setSelectedModel(null);
          }}
        />
        <MainContent>
          {(isAddingNewModel || models.length === 0) && !showPurchaseModal ? (
            <NewModelTrainer user={user} onModelAdded={handleModelAdded} />
          ) : showPurchaseModal ? (
            <PurchaseTokens onClose={() => setShowPurchaseModal(false)} />
          ) : selectedModel ? (
            selectedModel.trainingStatus === 'inProgress' ? (
              <ModelProgressUI selectedModel={selectedModel} />
            ) : (
              <ModelQueryUI
                selectedModel={selectedModel}
                prompt={prompt}
                setPrompt={setPrompt}
                handleGenerateImage={handleGenerateImage}
                generatedImages={generatedImages}
                isLoadingImages={isLoadingImages}
              />
            )
          ) : (
            <PlaceholderText>Select a model to start generating images.</PlaceholderText>
          )}
        </MainContent>
      </ContentContainer>
    </PageContainer >
  );
};
export default CustomModelsPage;
