// src/ModelTrainingComponents/NewModelTrainer.tsx
import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import {
  addDoc,
  collection,
  runTransaction,
  serverTimestamp,
  doc,
  setDoc,
  getDoc,
  onSnapshot,
  DocumentSnapshot,
} from 'firebase/firestore';
import { db, storage, auth } from '../firebaseConfig';
import { ref, uploadBytes } from 'firebase/storage';
import {
  FaUser,
  FaHeart,
  FaPaw,
  FaArrowRight,
  FaArrowLeft,
  FaInfoCircle,
  FaCloudUploadAlt,
} from 'react-icons/fa';

// Define the new interface
interface ImageWithPreview {
  file: File;
  preview: string;
}

// Model type definition
type Model = {
  id: string;
  trainingStatus: string;
  modelName: string;
  storagePath: string;
  timestamp: any; // Could be Firebase Timestamp or Date
  userEmail: string;
  triggerWord: string;
};

// Color Palette for Enhanced Light Theme
const colors = {
  background: '#f5f7fa', // Very Light Gray-100 for overall background
  primary: '#4f46e5', // Purple-600 for primary actions
  primaryLight: '#7c3aed', // Purple-500 for lighter accents
  primaryDark: '#3730a3', // Purple-700 for dark accents
  secondary: '#10b981', // Green-500 for secondary actions
  secondaryLight: '#34d399', // Green-400 for lighter accents
  secondaryDark: '#059669', // Green-600 for dark accents
  surface: '#ffffff', // White for surfaces (cards, inputs)
  text: '#1f2937', // Gray-800 for primary text
  textSecondary: '#4b5563', // Gray-600 for secondary text
  border: '#e5e7eb', // Gray-200 for borders
  accent: '#3b82f6', // Blue-500 for accents
  danger: '#ef4444', // Red-500 for errors
  info: '#38bdf8', // Light Blue-400 for informational elements
  white: '#ffffff', // White color for specific uses
  sidebarBackground: '#d1d5db', // Gray-300 for sidebar (slightly darker than background)
};

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const Container = styled.div`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 24px;
  background: linear-gradient(135deg, #ffffff 0%, #f8f9ff 100%);
  border-radius: 24px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  animation: ${fadeIn} 0.6s ease-out;

  @media (max-width: 768px) {
    width: 95%;
    padding: 24px 16px;
  }
`;

const InfoAlert = styled.div<{ variant?: 'success' | 'error' | 'warning' }>`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  margin: 16px 0;
  border-radius: 12px;
  background: ${props => {
    switch (props.variant) {
      case 'success': return 'rgba(16, 185, 129, 0.1)'; // Using the secondary color from your palette
      case 'error': return 'rgba(239, 68, 68, 0.1)';
      case 'warning': return 'rgba(245, 158, 11, 0.1)';
      default: return 'rgba(59, 130, 246, 0.1)';
    }
  }};
  color: ${props => {
    switch (props.variant) {
      case 'success': return '#059669'; // Using secondaryDark from your palette
      case 'error': return '#dc2626';
      case 'warning': return '#d97706';
      default: return '#2563eb';
    }
  }};
`;

const StepProgress = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin: 32px 0;
`;

const Step = styled.div<{ active: boolean }>`
  width: 40px;
  height: 4px;
  border-radius: 2px;
  background: ${props => props.active ?
    'linear-gradient(90deg, #4a90e2, #845ef7)' :
    'rgba(0, 0, 0, 0.1)'};
  transition: all 0.3s ease;
`;

const Title = styled.h2`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 32px;
  background: linear-gradient(120deg, #4a90e2, #845ef7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
`;

const OptionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin: 32px 0;
`;

const Option = styled.button<{ selected?: boolean }>`
  padding: 48px 32px;
  background: ${props => props.selected ?
    'linear-gradient(135deg, #4a90e2, #845ef7)' :
    'white'};
  border: 2px solid ${props => props.selected ? '#845ef7' : '#eee'};
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
    border-color: #4a90e2;
  }

  svg {
    font-size: 2.5rem;
    color: ${props => props.selected ? 'white' : '#4a90e2'};
  }

  span {
    color: ${props => props.selected ? 'white' : '#333'};
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: -0.02em;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 16px;
  border: 2px solid #eee;
  border-radius: 12px;
  font-size: 1.125rem;
  transition: all 0.3s ease;
  margin-bottom: 24px;

  &:focus {
    border-color: #4a90e2;
    box-shadow: 0 0 0 4px rgba(74, 144, 226, 0.1);
    outline: none;
  }
`;

const ImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  gap: 16px;
  margin: 24px 0;
`;

const ImagePreview = styled.div`
  position: relative;
  aspect-ratio: 1;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  button {
    position: absolute;
    top: 8px;
    right: 8px;
    background: rgba(255, 255, 255, 0.9);
    border: none;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: #ff4757;
      color: white;
    }
  }
`;

const UploadZone = styled.div<{ isVerifying?: boolean }>`
  position: relative;
  aspect-ratio: 1;
  border: 2px dashed #4a90e2;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(74, 144, 226, 0.05);
  cursor: ${props => props.isVerifying ? 'wait' : 'pointer'};
  transition: all 0.3s ease;
  opacity: ${props => props.isVerifying ? 0.7 : 1};

  &:hover {
    background: rgba(74, 144, 226, 0.1);
    border-color: #845ef7;
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: ${props => props.isVerifying ? 'wait' : 'pointer'};
    ${props => props.isVerifying && 'pointer-events: none;'}
  }

  svg {
    font-size: 2rem;
    color: #4a90e2;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
`;

const Button = styled.button<{ primary?: boolean }>`
  padding: 16px 32px;
  background: ${props => props.primary ?
    'linear-gradient(90deg, #4a90e2, #845ef7)' :
    'transparent'};
  color: ${props => props.primary ? 'white' : '#666'};
  border: ${props => props.primary ? 'none' : '2px solid #eee'};
  border-radius: 12px;
  font-size: 1.125rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: ${props => props.primary ?
    '0 8px 16px rgba(74, 144, 226, 0.2)' :
    'none'};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;
// Functional Component
const NewModelTrainer: React.FC<{
  user: any;
  onModelAdded: (model: any) => void;
}> = ({ user, onModelAdded }) => {
  const [step, setStep] = useState(1);
  const [modelType, setModelType] = useState<string>('');
  const [personName, setPersonName] = useState('');
  const [images, setImages] = useState<ImageWithPreview[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progress, setProgress] = useState<number>(0);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<string | null>(null);
  const [isVerifying, setIsVerifying] = useState(false);

  const [tokenBalance, setTokenBalance] = useState<number | null>(null);
  const [isLoadingTokens, setIsLoadingTokens] = useState<boolean>(true);

  const TOKEN_COST = 10;

  // Cleanup object URLs to prevent memory leaks
  useEffect(() => {
    return () => {
      images.forEach((image) => URL.revokeObjectURL(image.preview));
    };
  }, [images]);

  // Fetch and listen to token balance
  useEffect(() => {
    if (!user || !user.email) return;

    const tokenDocRef = doc(db, 'userTokens', user.email);

    const unsubscribe = onSnapshot(
      tokenDocRef,
      (docSnapshot: DocumentSnapshot) => {
        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setTokenBalance(data.tokens);
        } else {
          // If the document doesn't exist, initialize it with a default token balance
          runTransaction(db, async (transaction) => {
            transaction.set(tokenDocRef, {
              userId: user.email,
              tokens: 20, // Initial token balance
              lastUpdated: serverTimestamp(),
            });
          })
            .then(() => {
              setTokenBalance(20);
            })
            .catch((error) => {
              console.error('Error initializing token balance:', error);
              setTokenBalance(null);
            });
        }
        setIsLoadingTokens(false);
      },
      (error: Error) => {
        console.error('Error fetching token balance:', error);
        setIsLoadingTokens(false);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [user]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    setIsVerifying(true);

    try {
      const fileToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64 = reader.result?.toString().split(',')[1];
            resolve(base64 || '');
          };
          reader.onerror = reject;
        });
      };

      const newFiles = Array.from(files);
      const base64Images = await Promise.all(newFiles.map(fileToBase64));

      // Verify images
      const response = await fetch('https://us-central1-photoop-28a4c.cloudfunctions.net/verify_images', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ images: base64Images })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      if (!result.all_safe) {
        alert('One or more images contain inappropriate content. Please review your selection.');
        return;
      }

      const newImages: ImageWithPreview[] = newFiles.map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));

      setImages((prev) => [...prev, ...newImages]);

    } catch (error) {
      console.error('Error during image verification:', error);
      alert('Error verifying images. Please try again.');
    } finally {
      setIsVerifying(false);
    }
  };

  const handleRemoveImage = (index: number, event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    const imageToRemove = images[index];
    URL.revokeObjectURL(imageToRemove.preview); // Revoke the object URL
    setImages((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    if (!user || !personName.trim()) {
      alert('You must be signed in and provide a name for the model.');
      return;
    }

    if (tokenBalance === null) {
      alert('Token balance is loading. Please wait and try again.');
      return;
    }

    // if (tokenBalance < TOKEN_COST) {
    //   alert('Insufficient tokens. Please purchase more tokens to train a new model.');
    //   return;
    // }

    if (images.length < 5) {
      alert('Please upload at least 5 images to train the model.');
      return;
    }

    setIsSubmitting(true);
    setIsError(null);
    setIsSuccess(false);

    try {
      // Define Firestore document references
      const userTokenDocRef = doc(db, 'userTokens', user.email);
      const userModelsCollectionRef = collection(db, 'image_models', user.email, 'models');
      const newModelDocRef = doc(userModelsCollectionRef); // Auto-generated ID

      // Transaction to deduct tokens and create model document
      await runTransaction(db, async (transaction) => {
        const tokenDoc = await transaction.get(userTokenDocRef);
        if (!tokenDoc.exists()) {
          throw new Error('Token document does not exist.');
        }

        const currentTokens = tokenDoc.data().tokens;
        if (currentTokens < TOKEN_COST) {
          throw new Error('Insufficient tokens.');
        }

        // Deduct tokens
        transaction.update(userTokenDocRef, {
          tokens: currentTokens - TOKEN_COST,
          lastUpdated: serverTimestamp(),
        });

        // Prepare model data
        const modelData = {
          modelId: newModelDocRef.id,
          modelName: `${personName.trim()} style`,
          triggerWord: personName.trim(),
          userEmail: user.email,
          timestamp: serverTimestamp(),
          storagePath: `/model_training_photos/${newModelDocRef.id}`,
          trainingStatus: 'inProgress',
        };

        // Create model document
        transaction.set(newModelDocRef, modelData);
      });

      const docSnapshot = await getDoc(newModelDocRef);
      if (!docSnapshot.exists()) {
        throw new Error('Failed to create model document - please try again');
      }

      // Prepare the new model data
      const newModelData: Model = {
        id: newModelDocRef.id,
        modelName: `${personName.trim()} style`,
        triggerWord: personName.trim(),
        userEmail: user.email,
        storagePath: `/model_training_photos/${newModelDocRef.id}`,
        trainingStatus: 'inProgress',
        timestamp: new Date(), // Replace with actual timestamp if needed
      };

      // Upload images to Firebase Storage
      const uploadPromises = images.map((image, index) => {
        const storageRefPath = `model_training_photos/${newModelDocRef.id}/${index}_${image.file.name}`;
        const storageRef = ref(storage, storageRefPath);
        return uploadBytes(storageRef, image.file); // Pass image.file instead of image
      });

      await Promise.all(uploadPromises);

      // Prepare order data for cloud function
      const orderData = {
        userEmail: user.email,
        modelId: newModelData.id,
        triggerWord: newModelData.triggerWord,
        modelTrainingOnly: true,
      };

      // Get ID token for authentication
      const idToken = await user.getIdToken();

      // Trigger the cloud function
      const response = await fetch(
        'https://us-central1-photoop-28a4c.cloudfunctions.net/publish_order',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify(orderData),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error from publish_order:', errorText);
        throw new Error('Failed to publish order.');
      }

      // Notify parent component of the new model
      onModelAdded(newModelData);
      setIsSuccess(true);
    } catch (error: any) {
      console.error('Error during model submission:', error);
      setIsError(error.message || 'Failed to train the model. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };


  const canGoNext = () => {
    if (step === 1) return modelType !== '';
    if (step === 2) return personName.trim().length > 0;
    if (step === 3)
      return (
        images.length >= 5 &&
        !isSubmitting &&
        tokenBalance !== null &&
        tokenBalance >= TOKEN_COST
      );
    return false;
  };

  return (
    <Container>
      {isSuccess && (
        <InfoAlert variant="success">
          <FaInfoCircle />
          <span>Your style is being generated! {TOKEN_COST} tokens deducted.</span>
        </InfoAlert>
      )}

      {isError && (
        <InfoAlert variant="error">
          <FaInfoCircle />
          <span>{isError}</span>
        </InfoAlert>
      )}

      {step === 1 && (
        <>
          <Title>Who are you making images for?</Title>
          <StepProgress>
            <Step active={step >= 1} />
            <Step active={step >= 2} />
            <Step active={step >= 3} />
          </StepProgress>
          <OptionGrid>
            <Option
              selected={modelType === 'myself'}
              onClick={() => setModelType('myself')}
              aria-label="Select Myself"
            >
              <FaUser />
              <span>Myself</span>
            </Option>
            <Option
              selected={modelType === 'pet'}
              onClick={() => setModelType('pet')}
              aria-label="Select Pet"
            >
              <FaPaw />
              <span>Pet</span>
            </Option>
            <Option
              selected={modelType === 'loved'}
              onClick={() => setModelType('loved')}
              aria-label="Select Other"
            >
              <FaHeart />
              <span>Other</span>
            </Option>
          </OptionGrid>
        </>
      )}

      {step === 2 && (
        <>
          <Title>What is the name of this {modelType === 'pet' ? 'pet' : 'person'}?</Title>
          <StepProgress>
            <Step active={step >= 1} />
            <Step active={step >= 2} />
            <Step active={step >= 3} />
          </StepProgress>
          <Input
            value={personName}
            onChange={(e) => setPersonName(e.target.value)}
            placeholder="Enter their name"
            aria-label="Enter name"
          />
          <InfoAlert>
            <FaInfoCircle />
            <span>This name will be used in your prompts when generating images.</span>
          </InfoAlert>
        </>
      )}

      {step === 3 && (
        <>
          <Title>Add Your Images</Title>
          <StepProgress>
            <Step active={step >= 1} />
            <Step active={step >= 2} />
            <Step active={step >= 3} />
          </StepProgress>
          <InfoAlert variant="success">
            <FaInfoCircle />
            <span>Model training costs {TOKEN_COST} tokens. You have <strong>{tokenBalance}</strong> tokens available.</span>
          </InfoAlert>
          <InfoAlert>
            <FaInfoCircle />
            <span>Upload at least 5 clear photos of {personName}. More images improve quality.</span>
          </InfoAlert>
          <ImageGrid>
            {images.map((image, index) => (
              <ImagePreview key={index}>
                <img src={image.preview} alt={`Preview ${index + 1}`} loading="lazy" />
                <button onClick={(e) => handleRemoveImage(index, e)} aria-label="Remove Image">×</button>
              </ImagePreview>
            ))}
            <UploadZone isVerifying={isVerifying}>
              <input
                type="file"
                onChange={handleFileChange}
                multiple
                accept=".png,.jpg,.jpeg"
                aria-label="Upload Images"
                disabled={isVerifying}
              />
              {isVerifying ? <span>Verifying...</span> : <FaCloudUploadAlt />}
            </UploadZone>
          </ImageGrid>
          {tokenBalance !== null && tokenBalance < TOKEN_COST && (
            <InfoAlert variant="error">
              <span>You do not have enough tokens to train a new model.</span>
            </InfoAlert>
          )}
        </>
      )}

      <ButtonContainer>
        {step > 1 && (
          <Button onClick={() => setStep(prev => prev - 1)} aria-label="Go Back">
            <FaArrowLeft /> Back
          </Button>
        )}
        {step < 3 ? (
          <Button
            primary
            disabled={!canGoNext()}
            onClick={() => setStep(prev => prev + 1)}
            aria-label="Next Step"
          >
            Next <FaArrowRight />
          </Button>
        ) : (
          <Button
            primary
            disabled={!canGoNext()}
            onClick={handleSubmit}
            aria-label="Submit for Training"
          >
            {isSubmitting ? 'Submitting...' : 'Generate your style'}
          </Button>
        )}
      </ButtonContainer>
    </Container>
  );
};

export default NewModelTrainer;
