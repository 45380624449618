import React, { useState } from 'react';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import PhotoUpload from './PhotoUpload';
import { useNavigate } from 'react-router-dom';
import { analytics } from './firebaseConfig';
import { logEvent } from 'firebase/analytics';
import Select from 'react-select';
import { auth } from './firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';
import Navbar from './Navbar';
import { ShieldCheck } from 'lucide-react';
const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;
const colors = {
  primary: '#2563EB',
  secondary: '#3B82F6',
  background: '#F9FAFB',
  surface: '#FFFFFF',
  error: '#DC2626',
  onPrimary: '#FFFFFF',
  onBackground: '#111827',
};
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
  body, html {
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    background-color: #ffffff;
    color: #333333;
    height: 100%;
    overflow-x: hidden;
  }
  *, *::before, *::after { box-sizing: border-box; }
`;
const PrivacyNotice = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  background: linear-gradient(135deg, #EFF6FF 0%, #F1F5F9 100%);
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(37, 99, 235, 0.1);
  margin-bottom: 24px;
  border: 1px solid rgba(59, 130, 246, 0.1);
`;
const IconContainer = styled.div`
  color: #2563EB;
  background: rgba(37, 99, 235, 0.1);
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PrivacyText = styled.div`
  flex: 1;
  font-size: 0.95rem;
  color: #1E293B;
  line-height: 1.6;
`;
const FullscreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding-bottom: 160px;  // Increase padding on mobile
  }
`;
const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 48px;
  padding: 48px 64px;
  max-width: 1450px;
  margin: 0 auto;
  animation: ${fadeIn} 0.8s ease-out;
  @media (min-width: 1920px) { max-width: 1850px; }
  @media (max-width: 1024px) { padding: 36px 48px; }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 24px 32px;
    gap: 32px;
  }
`;

const Card = styled.div<{ disabled?: boolean }>`
  background: ${props => props.disabled ? '#F8FAFC' : colors.surface};
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border: 1px solid #E5E7EB;
  width: 100%;
  transition: all 0.3s ease;
  position: relative;
  
  ${props => props.disabled && `
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(2px);
      border-radius: 16px;
      z-index: 1;
    }
  `}

  &:hover {
    box-shadow: ${props => props.disabled ?
    '0 4px 12px rgba(0, 0, 0, 0.05)' :
    '0 6px 16px rgba(0, 0, 0, 0.08)'};
  }
`;

const DisabledOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(248, 250, 252, 0.1);
  border-radius: 16px;
  z-index: 2;
  padding: 24px;
  text-align: center;
`;

const UploadPrompt = styled.div`
  color: #64748B;
  font-size: 0.95rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  strong {
    color: #334155;
  }
`;

const PhotoCount = styled.div<{ complete?: boolean }>`
  color: ${props => props.complete ? '#10B981' : '#64748B'};
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 8px;
`;

const Title = styled.h1`
  font-size: 1.7rem;
  font-weight: 700;
  color: #1E293B;
  margin: 0 0 24px 0;
  letter-spacing: -0.02em;
  @media (max-width: 768px) { font-size: 1.5rem; }
`;
const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const FormField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
const Label = styled.label`
  font-size: 0.95rem;
  font-weight: 600;
  color: #1E293B;
`;
const ErrorMessage = styled.span`
  color: ${colors.error};
  font-size: 0.85rem;
  margin-top: 4px;
`;
const PhotoUploadWrapper = styled.div`
  flex: 1.2;
  min-width: 0;
`;
const TextContainer = styled.div`
  flex: 1;
  min-width: 0;
`;
const BottomNav = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  background: ${colors.surface};
  border-top: 1px solid #E5E7EB;
  z-index: 1000;
  @media (max-width: 480px) { padding: 12px 24px; }
`;
const NavButton = styled.button<{ primary?: boolean }>`
  background: ${({ primary }) => primary ?
    'linear-gradient(135deg, #3B82F6 0%, #2563EB 100%)' :
    'transparent'};
  color: ${({ primary }) => primary ? colors.onPrimary : colors.primary};
  padding: 12px 24px;
  font-size: 0.95rem;
  font-weight: 600;
  border: ${({ primary }) => primary ? 'none' : `2px solid ${colors.primary}`};
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-1px);
    box-shadow: ${({ primary }) => primary ?
    '0 4px 12px rgba(37, 99, 235, 0.25)' :
    '0 2px 8px rgba(37, 99, 235, 0.15)'};
  }
  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
  @media (max-width: 480px) {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
`;

// First define the type for our select styles
interface SelectStylesType {
  control: (base: any, state: any) => any;
  option: (base: any, state: any) => any;
  menu: (base: any) => any;
}

// Then create our styles object with the type
const customSelectStyles: SelectStylesType = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: state.isFocused ? colors.primary : '#E5E7EB',
    boxShadow: state.isFocused ? `0 0 0 2px ${colors.primary}20` : 'none',
    borderRadius: '10px',
    padding: '4px',
    minHeight: '48px',
    opacity: state.isDisabled ? 0.6 : 1,
    backgroundColor: state.isDisabled ? '#F8FAFC' : colors.surface,
    '&:hover': {
      borderColor: state.isDisabled ? '#E5E7EB' : colors.primary
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isDisabled ? '#F8FAFC' :
      state.isFocused ? '#EFF6FF' : colors.surface,
    color: state.isDisabled ? '#94A3B8' : colors.onBackground,
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    '&:active': {
      backgroundColor: '#DBEAFE'
    },
  }),
  menu: (provided: any) => ({
    ...provided,
    borderRadius: '10px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    border: '1px solid #E5E7EB',
  }),
};

interface OptionType { value: string; label: string; }
interface StartingPageProps {
  onPhotoUpload: (newPhotos: File[]) => void;
  onGenderAndEthnicitySelect: (gender: string, ethnicity: string) => void;
}
const StartingPage: React.FC<StartingPageProps> = ({ onPhotoUpload, onGenderAndEthnicitySelect }) => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [gender, setGender] = useState<OptionType | null>(null);
  const [ethnicity, setEthnicity] = useState<OptionType | null>(null);
  const [errors, setErrors] = useState<{ gender?: string; ethnicity?: string; photos?: string }>({});
  const [photosUploaded, setPhotosUploaded] = useState<File[]>([]);
  const handlePhotoUpload = (newPhotos: File[]) => {
    setPhotosUploaded(newPhotos);
    onPhotoUpload(newPhotos);
    if (newPhotos.length > 0) {
      setErrors(prev => ({ ...prev, photos: undefined }));
    }
  };
  const handleNext = () => {
    const newErrors: { gender?: string; ethnicity?: string; photos?: string } = {};
    if (!gender) newErrors.gender = 'Please select your gender';
    if (!ethnicity) newErrors.ethnicity = 'Please select your ethnicity';
    if (photosUploaded.length === 0) newErrors.photos = 'Please upload at least one photo';
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0 && gender && ethnicity) {
      onGenderAndEthnicitySelect(gender.value, ethnicity.value);
      if (analytics) logEvent(analytics, 'step2_next_button_clicked', {});
      navigate('/purchase');
    }
  };
  const genderOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' },
    { value: 'non-binary', label: 'Non-Binary' },
    { value: 'prefer-not-to-say', label: 'Prefer not to say' },
  ];
  const ethnicityOptions = [
    { value: 'asian', label: 'Asian' },
    { value: 'black', label: 'Black' },
    { value: 'hispanic', label: 'Hispanic' },
    { value: 'white', label: 'White' },
    { value: 'indian', label: 'Indian' },
    { value: 'mixed', label: 'Mixed' },
    { value: 'other', label: 'Other' },
    { value: 'prefer-not-to-say', label: 'Prefer not to say' },
  ];
  return (
    <>
      <GlobalStyle />
      <FullscreenContainer>
        <Navbar navItems={[{ label: 'Home', to: '/' }]} showSignOutButton={!!user} currentStep={2} />
        <ContentContainer>
          <PhotoUploadWrapper>
            <Card>
              <PhotoUpload onPhotoUpload={handlePhotoUpload} />
              {errors.photos && <ErrorMessage>{errors.photos}</ErrorMessage>}
            </Card>
          </PhotoUploadWrapper>
          <TextContainer>
            <PrivacyNotice>
              <IconContainer><ShieldCheck size={24} /></IconContainer>
              <PrivacyText>
                <strong>Your Privacy Matters:</strong> Your uploaded photos are permanently deleted once the order is complete. <strong>We never sell or save your data</strong>.
              </PrivacyText>
            </PrivacyNotice>
            <Card disabled={photosUploaded.length < 5}>
              <Title>Personalize Your AI</Title>
              <FormSection>
                <FormField>
                  <Label htmlFor="gender">Select Gender</Label>
                  <Select
                    id="gender"
                    instanceId="gender-select"
                    value={gender}
                    onChange={(option) => setGender(option as OptionType)}
                    options={genderOptions}
                    placeholder="Select your gender"
                    styles={customSelectStyles}
                    isDisabled={photosUploaded.length < 5}
                  />
                  {errors.gender && <ErrorMessage>{errors.gender}</ErrorMessage>}
                </FormField>
                <FormField>
                  <Label htmlFor="ethnicity">Select Ethnicity</Label>
                  <Select
                    id="ethnicity"
                    instanceId="ethnicity-select"
                    value={ethnicity}
                    onChange={(option) => setEthnicity(option as OptionType)}
                    options={ethnicityOptions}
                    placeholder="Select your ethnicity"
                    styles={customSelectStyles}
                    isDisabled={photosUploaded.length < 5}
                  />
                  {errors.ethnicity && <ErrorMessage>{errors.ethnicity}</ErrorMessage>}
                </FormField>
              </FormSection>

              {photosUploaded.length < 5 && (
                <DisabledOverlay>
                  {/* <UploadPrompt>
                    <PhotoCount>
                      {photosUploaded.length}/5 Photos
                    </PhotoCount>
                    <strong>Upload photos to continue</strong>
                  </UploadPrompt> */}
                </DisabledOverlay>
              )}
            </Card>
          </TextContainer>
        </ContentContainer>
        <BottomNav>
          <NavButton onClick={() => navigate('/select-template')}>Back</NavButton>
          <NavButton primary onClick={handleNext}
            disabled={!gender || !ethnicity || photosUploaded.length < 5}>
            Next
          </NavButton>
        </BottomNav>
      </FullscreenContainer>
    </>
  );
};
export default StartingPage;